<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			<br /> 
			<el-row>
				<el-col class="yu_input_label" :span="8">
					活动名称:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_hudo_title"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					发布人昵称:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_hudo_name"></el-input>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					级别:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_hudo_jibie"></el-input>
				</el-col>
			</el-row>
			<br />
			  
			<el-row>
				<el-col class="yu_input_label" :span="8">
					标签:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_hudo_label"></el-input>
				</el-col>
			</el-row>
			<br />
			
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					 日期:
				</el-col>
				<el-col :span="16">
					<el-select v-model="m_riqi" placeholder="请选择" style="width: 100%;">
						<el-option v-for="item in m_riqi_list" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />
			
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					活动说明:
				</el-col>
				<el-col :span="16">
					<el-input type="textarea" v-model="m_hudo_text"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					门票价格:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_hudo_menpiao"></el-input>
				</el-col>
			</el-row>
			<br />
			  
			<el-row class="yu_content_right">
				<el-button @click="huodong_add" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import huodong_add_post from "./expand/huodong_add_post.js";

	export default {

		props: ["parent"],
		data() {
			return {
				m_hudo_title: "",
				m_hudo_name: "",
				m_hudo_jibie: "",  
				m_hudo_label: "",
				m_hudo_text: '',
				m_hudo_menpiao: '', 
				m_riqi : "",
				m_riqi_list :[{
					value: 1,
					label: '今天'
				}, {
					value: 2,
					label: '明天'
				}, {
					value: 3,
					label: '后天'
				}, {
					value: 4,
					label: '大后天'
				}, {
					value: 4,
					label: '大大后天'
				}] 
			}
		},
		created() {
			const that = this;
			// that.shoppe_add_info_get = new shoppe_add_info_get(that);
			// that.shoppe_add_info_get.m_main();
		},
		methods: {
			huodong_add() {
				const that = this;
				that.huodong_add_post = new huodong_add_post(that);
				that.huodong_add_post.m_main();
			},
			handleAvatarSuccess(res, file) {
				const that = this;
				console.log("dd");
				console.log(res.upload_file);

				that.m_img2 = res.img_val;
				this.m_img = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
