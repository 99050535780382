import request from "tools/request.js"

class huodong_set_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		
		  
		let m_hudo_id = that.parent.m_hudo_id; 
		 
		let session = localStorage.getItem("session");
		
		let params = { 
			m_hudo_id : m_hudo_id, 
			session : session
		}  
		
		request.post('/huodong/huodong_set_get',params).then(res => {
		 
			that.parent.m_huodong_info = res.data.result;  
		})
		 
	}
}

export default huodong_set_get