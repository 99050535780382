<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
	 
			
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					活动名称:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_huodong_info.hudo_title"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					发布人昵称:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_huodong_info.hudo_name"></el-input>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					级别:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_huodong_info.hudo_jibie"></el-input>
				</el-col>
			</el-row>
			<br />
			  
			<el-row>
				<el-col class="yu_input_label" :span="8">
					标签:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_huodong_info.hudo_label"></el-input>
				</el-col>
			</el-row>
			<br />
			
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					 日期:
				</el-col>
				<el-col :span="16"> 
						<el-input v-model="m_huodong_info.hudo_time"></el-input> 
				</el-col>
			</el-row>
			<br />
			
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					活动说明:
				</el-col>
				<el-col :span="16">
					<el-input type="textarea" v-model="m_huodong_info.hudo_text"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					门票价格:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_huodong_info.hudo_menpiao"></el-input>
				</el-col>
			</el-row>
			<br />


			<br />
			<el-row class="yu_content_right">
				<el-button @click="huodong_set" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import huodong_set_get from "./expand/huodong_set_get.js";
	import huodong_set_post from "./expand/huodong_set_post.js";

	export default {

		props: ["m_hudo_id", "parent"],
		data() {
			return {
				m_huodong_info:{}, 
			 
			}
		},
		created() {
			const that = this;
			
			console.log("3333");
			console.log(that.m_hudo_id);
			
			that.huodong_set_get = new huodong_set_get(that);
			that.huodong_set_get.m_main();
		},
		methods: {
			huodong_set() {
				const that = this;
				that.huodong_set_post = new huodong_set_post(that);
				that.huodong_set_post.m_main();
			} 
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>