import request from "tools/request.js"

//添加活动
class huodong_add_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;



		let session = localStorage.getItem("session");

 

		let params = {
			m_hudo_title: that.parent.m_hudo_title,
			m_hudo_name: that.parent.m_hudo_name,
			m_hudo_jibie: that.parent.m_hudo_jibie,  
			m_hudo_label: that.parent.m_hudo_label,  
			m_hudo_text: that.parent.m_hudo_text,
			m_hudo_menpiao: that.parent.m_hudo_menpiao,
			m_riqi: that.parent.m_riqi, 
			session: session
		}

		request.post('/huodong/huodong_add_post', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success'
				});
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			
			that.parent.parent.drawer_1 = false;
			that.parent.parent.huodong_get.m_main();
		})

	}
}

export default huodong_add_post